
import { computed, defineComponent, ref, watch } from "vue";
import { LoadEntitiesFromEntityEvent } from "@/core/composite/composite";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import store from "@/store";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "Roles",
  components: {BaseMultiSelect, BaseForm, BaseModal, QuickAction, Entities},
  props: {
    id: {type: String}
  },
  setup() {
    const model = ref({roles: []});
    const submitting = ref(false)
    store.dispatch(Actions.LOAD_ROLES)
    const roles = computed(() => store.state.UserModule.roles)
    const userRoles = computed(() => store.state.UserModule.userRoles)
    model.value.roles = userRoles.value.map(item => item.id);
    watch(userRoles, (cb) => {
      model.value.roles = cb.map(item => item.id);
    })


    const onRoleDiscard = () => {
      model.value.roles = [];
    }

    return {
      ...LoadEntitiesFromEntityEvent(Actions.LOAD_USER_ROLES),
      submitting,
      roles,
      model,
      onRoleDiscard,
      userRoles,
    }
  },
  methods: {
    onRoleSubmit() {
      this.submitting = false;
      UserService.addRole(this.id, this.model).then(res => {
        store.commit(Mutations.SET_USER_ROLES, res)
        const baseModal = this.$refs.roleFormRef as typeof BaseModal
        baseModal.hideBaseModal()
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
